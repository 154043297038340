import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


$('.top_slider').slick({
    arrows: false,
    dots: false,
    fade: true,
    infinite: true,
    easing: 'linear',
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    pauseOnFocus: false,
    
  });