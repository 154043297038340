$(function () {
  var ua = navigator.userAgent;
  if ((ua.indexOf('iPhone') > 0) || ua.indexOf('iPod') > 0 || (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)) {
    $('head').prepend('<meta name="viewport" content="width=device-width,initial-scale=1">');
  } else {
    $('head').prepend('<meta name="viewport" content="width=1366">');
  }
});

$(function () {
  var $elem = $('.posSwitch');
  var replaceWidth = 1001;
  function posSwitch() {
    var windowWidth = parseInt($(window).width());
    $elem.each(function () {
      var $this = $(this).find('.posSwitchTarget');
      var posFlgA = $(this).find('.posFlgA');
      var posFlgB = $(this).find('.posFlgB');
      if (windowWidth >= replaceWidth) {
        $this.appendTo(posFlgA);
      } else {
        $this.prependTo(posFlgB);
      }
    });
  }
  posSwitch();
  var resizeTimer;
  $(window).on('resize', function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      posSwitch();
    }, 100);
  });
});


function moveAnimation(){

    var randomElm = $(".momoArea");//親要素取得
    var randomElmChild = $(randomElm).children();//親の子要素を取得
    if(!$(randomElm).hasClass("play")){	//親要素にクラス名playが付いてなければ処理をおこなう
      randomAnime();	
    }
    
    function randomAnime(){
      $(randomElm).addClass("play");//親要素にplayクラスを付与
      var rnd = Math.floor(Math.random() * randomElmChild.length); //配列数からランダム数値を取得
      var moveData = "fadeUp";//アニメーション名＝CSSのクラス名を指定
      $(randomElmChild[rnd]).addClass(moveData);//アニメーションのクラスを追加
      randomElmChild.splice(rnd,1);//アニメーション追加となった要素を配列から削除
      if(randomElmChild.length == 0 ){//配列の残りがあるか確認
        $(randomElm).removeClass("play");//なくなった場合は親要素のplayクラスを削除
      }else{
        setTimeout(function(){randomAnime();},200);	//0.5秒間隔でアニメーションをスタートさせる。※ランダムのスピード調整はこの数字を変更させる	
      }
      
    }
    

  }
  
    var animeFlag = true;
  
    $(window).on('load', function(){
      moveAnimation();
    });

    var windowWidth = $(window).width();
var windowSm = 768;
if (windowWidth <= windowSm) {
//横幅768px以下（スマホ）に適用させるJavaScriptを記述
} else {
//横幅768px以上（PC、タブレット）に適用させるJavaScriptを記述

(function(){
      const container = document.getElementById('container'); // コンテンツエリアを囲む要素
      const aside = document.getElementById('aside');         // サイドメニュー要素
      const offsetY = 20;  // ピッタリ上にくっつかないように少し余白を持たせる
      container.style.minHeight = aside.clientHeight + 'px'; 
      window.addEventListener('scroll',()=>{
        const containerRect = container.getBoundingClientRect();
        const isReachBottom = (aside,containerRect,offsetY)=>{
          if( aside.clientHeight < window.innerHeight ){
            if(containerRect.bottom <= aside.clientHeight + offsetY){
              return true;
            }else{
              return false;
            }
          }else{
            if(containerRect.bottom < window.innerHeight - offsetY){
              return true;
            }else{
              return false;
            }
          }
        }
    
        // ↓↓↓↓↓図で説明↓↓↓↓
        if( isReachBottom(aside,containerRect,offsetY) ){
          // ①メインコンテンツの一番下までスクロールしたとき
          aside.classList.add('is-end');
          aside.classList.add('fixed');
          aside.style.left = '';
        }else if( containerRect.top < offsetY ){
          // ②メインコンテンツ内をスクロールしているとき
          aside.classList.add('fixed');
          aside.classList.remove('is-end');
          aside.style.left = containerRect.left + 'px';
        }else{
          // ③メインコンテンツより上をスクロールしているとき
          aside.classList.remove('fixed');
          aside.classList.remove('is-end');
          aside.style.left = '';
        }
      });
    })();


}
    
    
  

   /* $(function() {
      var nav = $('.navArea');
      //表示位置
      var navTop = nav.offset().top+300;
      //ナビゲーションの高さ（シャドウの分だけ足してます）
      var navHeight = nav.height()+10;
      var showFlag = false;
      nav.css('top', -navHeight+'px');
      //ナビゲーションの位置まできたら表示
      $(window).on('scroll', function () {
          var winTop = $(this).scrollTop();
          if (winTop >= navTop) {
              if (showFlag == false) {
                  showFlag = true;
                  nav
                      .addClass('fixed')
                      .stop().animate({'top' : '0px'}, 200);
              }
          } else if (winTop <= navTop) {
              if (showFlag) {
                  showFlag = false;
                  nav.stop().animate({'top' : -navHeight+'px'}, 200, function(){
                      nav.removeClass('fixed');
                  });
              }
          }
      });
  });*/
