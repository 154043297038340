$('footer .nav-sec .acc-trigger').on('click', function () {
  $(this).next().stop().slideToggle();
});

$('.p-recruit .faq-sec .faq_list .list .el_btn3').on('click', function () {
  $(this).parent("dt").next(".a_item").stop().slideToggle();
  $(this).toggleClass("open");
});




